//@ts-nocheck

(function () {
  var targetClassname = "monalixa-beacon";
  var beaconFiredClassname = "monalixa-beacon-fired";
  var beaconUrlAttribute = "data-monalixa-beacon-url";
  var throttleDelay = 200;

  var browserHeight = 0;
  var browserWidth = 0;

  var fireBeacon = function (url) {
    var img = new Image();
    img.src = url;
  };

  var setBrowserDimensions = function () {
    browserHeight = window.innerHeight || document.documentElement.clientHeight;
    browserWidth = window.innerWidth || document.documentElement.clientWidth;
  };

  var elementInViewport = function (elem) {
    var elRect = elem.getBoundingClientRect();
    var screenLeft = 0;
    var screenTop = 0;

    // is element on screen ?
    if (
      elRect.height > 0 &&
      elRect.width > 0 &&
      elRect.left <= browserWidth &&
      elRect.right >= screenLeft &&
      elRect.top <= browserHeight &&
      elRect.bottom >= screenTop
    ) {
      // area of element on screen
      var intersect = {
        height:
          Math.min(elRect.bottom, browserHeight) -
          Math.max(elRect.top, screenTop),
        width:
          Math.min(elRect.right, browserWidth) -
          Math.max(elRect.left, screenLeft),
      };
      return (
        intersect.width * intersect.height >= 0.5 * elRect.width * elRect.height
      );
    }

    return false;
  };

  var resizeHandler = function () {
    setBrowserDimensions();
    beaconHandler();
  };

  var beaconHandler = function () {
    var monalixaNodes = document.getElementsByClassName(targetClassname);

    if (!monalixaNodes || monalixaNodes.length <= 0) {
      return;
    }

    for (var i = 0; i < monalixaNodes.length; i++) {
      var beaconNode = monalixaNodes[i];
      var url = beaconNode.getAttribute(beaconUrlAttribute);

      if (
        beaconNode.className.indexOf(beaconFiredClassname) < 0 &&
        url &&
        elementInViewport(beaconNode)
      ) {
        beaconNode.classList.add(beaconFiredClassname);
        fireBeacon(url);
      }
    }
  };

  var throttle = function (fn) {
    var waiting = false;

    return function () {
      if (!waiting) {
        fn();
        waiting = true;
        setTimeout(function () {
          waiting = false;
        }, throttleDelay);
      }
    };
  };

  var beaconHandlerT = throttle(beaconHandler);
  var resizeHandlerT = throttle(resizeHandler);

  setBrowserDimensions();

  if (window.addEventListener) {
    window.addEventListener("DOMContentLoaded", beaconHandler, false);
    window.addEventListener("load", beaconHandler, false);
    window.addEventListener("scroll", beaconHandlerT, false);
    window.addEventListener("resize", resizeHandlerT, false);
  } else {
    window.attachEvent("onDOMContentLoaded", beaconHandler);
    window.attachEvent("onload", beaconHandler);
    window.attachEvent("onscroll", beaconHandlerT);
    window.attachEvent("onresize", resizeHandlerT);
  }
})();
